import { FC } from "react";

import appStyles from "../../App.module.css";
import { privacyPolicyDocs } from "../../config/app-constants";

interface FooterProps {}

const Footer: FC<FooterProps> = (props: FooterProps) => {
  return (
    <div className={appStyles.footerContentWrapper}>
      <p>
        Eurochange Limited is an Introducer Appointed Representative of
        battleface Insurance Services Limited. battleface Insurance Services
        Limited is authorised and regulated by the Financial Conduct Authority
        in the UK (FRN 774757). Their registered address is Suite 102, 80
        Churchill Square, Kings Hill, West Malling, Kent, ME19 4YU, UK. By
        continuing, you agree to our
        <a
          href={`${privacyPolicyDocs.privacyPolicy}?${new Date().getTime()}`}
          target='_blank'
          rel='noopener noreferrer'
          // className={`${appStyles.textDecorationNone}`}
        >
          &nbsp;Privacy Policy
        </a>
        ,&nbsp;
        <a
          href={`${privacyPolicyDocs.termsCondition}?${new Date().getTime()}`}
          target='_blank'
          rel='noopener noreferrer'
          // className={`${appStyles.textDecorationNone}`}
        >
          Terms & Conditions
        </a>
        , and&nbsp;
        <a
          href={`${privacyPolicyDocs.cookiePolicy}?${new Date().getTime()}`}
          target='_blank'
          rel='noopener noreferrer'
          // className={`${appStyles.textDecorationNone}`}
        >
          Cookie Policy.
        </a>
      </p>
    </div>
  );
};

export default Footer;
