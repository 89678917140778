import appStyles from "../App.module.css";
import { SelectOption } from "./interfaces";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiUrl: process.env.REACT_APP_BASE_URL,
  singleTripProductId: process.env.REACT_APP_SINGLE_TRIP_PRODUCT_ID,
  multiTripProductId: process.env.REACT_APP_MULTI_TRIP_PRODUCT_ID,
  cookieConsentDebug: process.env.REACT_APP_COOKIE_CONSENT_DEBUG === "true",
  datePickerFormat: "dd/MM/y",
  excessFee1: 100,
  excessFee2: 250,
  multiExcessFee1: 100,
  multiExcessFee2: 250,
  publishableKey: process.env.REACT_APP_PUBLISHABLE_KEY,
  EMAIL_REGEX: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  affiliatedId: process.env.REACT_APP_AFFILIATED_ID,
  AMT: "amt",
  ST: "st",
  enableAMT: process.env.REACT_APP_ENABLE_AMT === "true",
  defaultAPIError:
    "We are unable to process your order online. Please contact our Customer Services team on +44 (20) 8089 5338 or at contact@battleface.com.",
};

// To hide base Package from the list of packages
export const packagesToHide: string[] = ["base_package"];

export const requiredPackages: string[] = [];

export const packageDisplayNames: any = {
  "Base Package": "Trip Duration",
  "Accidental Death Package": "Personal Accident",
  "Additional Baggage Package": "Baggage Loss or Delay",
};

export const packageDropDownPlaceholders: any = {
  accidental_death_package: "£50,000 included",
  additional_baggage_package: "£2,000 included",
};

export const packageBenefits: any = {
  winter_sports_package: "(excess £50/person, or £100/family)",
  accidental_death_package: "",
  additional_baggage_package: "",
};

export const packageAllowedMaxAges: any = {
  winter_sports_package: 71,
  activities_package: 66,
};

export const activitiesPackageDocs: { [key: string]: string } = {
  doc1: "/docs/activities_package/AMT-optional-sports-and-activities-070325.pdf",
  doc2: "/docs/activities_package/AMT-optional-sports-and-activities-070325.pdf",
  doc3: "/docs/activities_package/AMT-optional-sports-and-activities-070325.pdf",
};

export const privacyPolicyDocs: { [key: string]: string } = {
  singleTrip: "/docs/battleface-policy-Eurochange-ST-080325_gbp.pdf",
  multiTrip: "/docs/battleface-policy-Eurochange-AMT-080325_gbp.pdf",
  privacyPolicy: "/docs/Eurochange-battleface-privacy-policy-110823.pdf",
  cookiePolicy: "/docs/Eurochange-battleface-Cookie-Policy-110823.pdf",
  travelMedicalDisclosures: "/docs/Annual-Multi-Trip-Insurance-Disclosures.pdf",
  singleTripIPID: "/docs/non-eea_Eurochange-ST-IPID-080325-gbp.pdf",
  multiTripIPID: "/docs/UK_IPID-AMT-battleface-Eurochange-080325-gbp.pdf",
  termsCondition:
    "/docs/battleface-eurochange-terms-and-conditions-050923_gbp.pdf",
};

export const multiTripOption = [
  { code: "AX", name: "Europe" },
  { code: "AL", name: "WORLDWIDE excluding the Caribbean, Canada and USA" },
  { code: "DZ", name: "WORLDWIDE including the Caribbean, Canada and USA" },
];

export const multiTripRegions: { [key: string]: string } = {
  AX: "Europe",
  AL: "WORLDWIDE 1",
  DZ: "WORLDWIDE 2",
};

// Further information text will be shown in MultiTrip
export const amtDestinationInfo: { [key: string]: string } = {
  AX: "Europe including Republic of Ireland, Canary Islands, Madeira and countries bordering the Mediterranean, including the United Kingdom if Your Home Country is outside the United Kingdom, excluding Algeria, Belarus, Crimea, Chechnya, Syria, Israel (Gaza Strip and West Bank only), Libya, Russia and Ukraine.",
  AL: "WORLDWIDE excluding the Caribbean, Canada and USA, Afghanistan, Belarus, Crimea, Chechnya, Central African Republic, Cuba, Democratic Republic of Congo, Iraq, Iran, Israel (Gaza Strip and West Bank only), Libya, Nigeria, North Korea, Russia, South Sudan, Sudan, Somalia, Syria, Ukraine and Venezuela",
  DZ: "WORLDWIDE including the Caribbean, Canada and USA, but excluding Afghanistan, Belarus, Crimea, Chechnya, Central African Republic, Cuba, Democratic Republic of Congo, Iraq, Iran, Israel (Gaza Strip and West Bank only), Libya, Nigeria, North Korea, Russia, South Sudan, Sudan, Somalia, Syria, Ukraine and Venezuela.",
};

export const selectControlStyling = (state: any) => {
  const classes = [appStyles.bfFormControl];
  if (state.isFocused) {
    classes.push(appStyles.bfFormControlFocused);
  }
  return classes.join(" ");
};

export const selectOptionControlStyling = (state: any) => {
  const classes = [appStyles.bfFormControl];
  if (state.isSelected) {
    classes.push(appStyles.bfFormControlOptionSelected);
  }
  if (state.isFocused) {
    classes.push(appStyles.bfFormControlOptionFocused);
  }
  return classes.join(" ");
};

export const currencyOptions: SelectOption[] = [
  {
    label: "GBP",
    value: "GBP",
  },
];

export const convertDateToApiFormat = (date: Date | null) =>
  date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`
    : "";

// calculate Leap year
export const totalDaysInCurrentYear = (startDate: Date | null): number => {
  const _currentYear: any =
    startDate && new Date(startDate?.toString()).getFullYear();
  const _currentMonth: any =
    startDate && new Date(startDate.toString()).getMonth();
  return (startDate &&
    _currentYear % 4 === 0 &&
    _currentYear % 100 !== 0 &&
    _currentMonth <= 1) ||
    (_currentYear % 400 === 0 && _currentMonth <= 1)
    ? 365
    : 364;
};

// export const calculateAge = (dob: Date | null) => {
//   if (!dob) {
//     return 0;
//   }
//   const diff_ms = Date.now() - dob.getTime();
//   const age_dt = new Date(diff_ms);
//   return Math.abs(age_dt.getUTCFullYear() - 1970);
// };

export const calculateAge = (
  birthDate: Date | null,
  from: Date | null = null
) => {
  if (!birthDate) {
    return {
      years: 0,
      months: 0,
      days: 0,
    };
  }

  if (!from) {
    from = new Date();
  }

  // Calculate years
  let years;
  if (
    from.getMonth() > birthDate.getMonth() ||
    (from.getMonth() == birthDate.getMonth() &&
      from.getDate() >= birthDate.getDate())
  ) {
    years = from.getFullYear() - birthDate.getFullYear();
  } else {
    years = from.getFullYear() - birthDate.getFullYear() - 1;
  }

  // Calculate months
  let months = 0;
  if (from.getDate() >= birthDate.getDate()) {
    months = from.getMonth() - birthDate.getMonth();
  } else if (from.getDate() < birthDate.getDate()) {
    months = from.getMonth() - birthDate.getMonth() - 1;
  }
  // make month positive
  months = months < 0 ? months + 12 : months;

  // Calculate days
  let days;
  // days of months in a year
  let monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (from.getDate() >= birthDate.getDate()) {
    days = from.getDate() - birthDate.getDate();
  } else {
    days =
      from.getDate() - birthDate.getDate() + monthDays[birthDate.getMonth()];
  }

  return {
    years,
    months,
    days,
  };
};
// check that travellers age is above 66
export const validateAge = (
  _mainApplicant: any,
  _otherApplicant: any,
  _startDate: any
) => {
  const d = calculateAge(_mainApplicant, _startDate);

  if (d.years < 150 && d.years >= 66) {
    // Here check for 150 is to ensure that the year of birth entered is abobe 1900
    return "We've got You covered for trips lasting up to 31 days. If You require cover for longer adventures, please call Us on +44 (20) 4586 0607.";
  }

  for (let index = 0; index < _otherApplicant.length; index++) {
    const _otherApplicantDOB = _otherApplicant[index];

    const d2 = calculateAge(_otherApplicantDOB, _startDate);

    if (d2.years < 150 && d2.years >= 66) {
      // Here check for 150 is to ensure that the year of birth entered is abobe 1900
      return "We've got You covered for trips lasting up to 31 days. If You require cover for longer adventures, please call Us on +44 (20) 4586 0607.";
    }
  }
  return null;
};

// check that date is valid or not
export const isValidDate = (date: any) => {
  return convertDateToApiFormat(date).length === 10;
};

export const formatDate = (date: Date | null) =>
  date
    ? date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "";

// DD/MM/yyyy
export const formatTripDates = (date: Date | null) =>
  date ? date.toLocaleDateString("en-GB") : "";

export const formatAmount = (
  amount: number,
  country: string,
  currency: string,
  minFractionDigit: number = 2,
  maxFractionDigits: number = 2
) =>
  new Intl.NumberFormat(`en-${country}`, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: minFractionDigit,
    maximumFractionDigits: maxFractionDigits,
  }).format(amount);

export const addDays = (dateInput: Date, count: number): Date => {
  let _date: Date = new Date(dateInput);
  let increasedDate = new Date(_date.getTime() + count * 86400000);
  increasedDate.setHours(0, 0, 0, 0);
  return increasedDate;
};

export const subtractYears = (dateInput: Date, count: number): Date => {
  let _date = new Date(dateInput);
  _date.setFullYear(_date.getFullYear() - count);

  return _date;
};

export const subtractMonths = (dateInput: Date, count: number): Date => {
  let _date = new Date(dateInput);
  _date.setMonth(_date.getMonth() - count);
  return _date;
};

export const duration = (
  start: Date | null,
  end: Date | null,
  type: "d" | "m" | "y" = "d"
): number => {
  if (!start || !end) {
    return 0;
  }
  const difference = end.getTime() - start.getTime();
  let divisor = 1000 * 3600 * 24; // fpr days
  switch (type) {
    case "y":
      divisor = 1000 * 3600 * 24 * 365.25;
      return difference / divisor;
  }
  return Math.ceil(difference / divisor) + 1;
};

export const customPackageDescriptions: any = {
  accidental_death_package:
    "Coverage for Accidental Death and Permanent Total Disability in the event of an accident whilst travelling.",
  additional_baggage_package:
    "This benefit covers up to the limit chosen in the event your baggage gets lost, stolen or damaged (limits apply). In addition, should your baggage be delayed on your outward journey for a period in excess of 24 hours, this benefit will cover you for the purchase of essential items up to the amount of £300.",
  winter_sports_package:
    "The battleface Winter Sports Optional Cover provides protection for winter sports activities including skiing, snowboarding, ice skating, and sledging.",
};

export const AdditionalPackages: { [key: string]: any } = {
  emergency_medical_and_additional_expenses: {
    alias: "emergency_medical_and_additional_expenses",
    description:
      "This benefit covers unforseen injury or illness whilst travelling, including COVID-19 emergency medical expenses for travellers aged 65 or under (illness cover is not available if you are 75 years or over). This also includes evacuation or medical repatriation to the nearest quality care or all the way home if deemed medically necessary.",
    name: "Emergency Medical and Additional Expenses",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(Number(10000000), "US", "GBP", 0, 0)})`,
  },
  hospital_benefit: {
    alias: "hospital_benefit",
    description:
      "Payment for every complete 24 hours You have to stay in hospital as an in-patient, up to £100.",
    name: "Hospital Benefit",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(
      Number(25),
      "US",
      "GBP",
      0,
      0
    )} per day, maximum ${formatAmount(Number(100), "US", "GBP", 0, 0)})`,
  },
  travel_delay: {
    alias: "travel_delay",
    description:
      "This benefit covers you if you are delayed more than 12 hours due to the transport on which you were due to travel breaking down, strike, riots, bad weather, natural disaster, or security alert.",
    name: "Travel Delay",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(Number(500), "US", "GBP", 0, 0)})`,
  },
  personal_liability: {
    alias: "personal_liability",
    description:
      "This benefit covers you up to the amount of £2,000,000 if in the course of a trip you become legally liable for injury, death, or loss of property for someone else.",
    name: "Personal Liability",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(Number(2000000), "US", "GBP", 0, 0)})`,
  },
  personal_money_passport: {
    alias: "personal_money_passport",
    description:
      "This benefit covers up to the maximum limit of the lost or stolen money (cash limited to £250) or reasonable costs incurred abroad to obtain a replacement or temporary passport if it is lost or stolen whilst travelling.",
    name: "Personal Money and Passport",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(Number(500), "US", "GBP", 0, 0)})`,
  },
  legal_expenses: {
    alias: "legal_expenses",
    description:
      "Where our written consent is obtained, we will cover your incurred legal expenses in the pursuit of claims for damages against third parties who have caused an Insured Person’s death, bodily injury or illness through incidents occurring during the Trip.",
    name: "Legal Expenses",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(Number(25000), "US", "GBP", 0, 0)})`,
  },
  hijack: {
    alias: "hijack",
    description:
      "Costs and expenses incurred as a direct consequence of You being a victim of a hi-jack or kidnapping occurring during the Trip. ",
    name: "Hijack",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(
      Number(100),
      "US",
      "GBP",
      0,
      0
    )} per day, maximum ${formatAmount(Number(5000), "US", "GBP", 0, 0)})`,
  },
  pet_fees: {
    alias: "pet_fees",
    description:
      "Additional kennel or cattery charges that you incur if you have a valid claim under Emergency Medical & Repatriation, and your return to your home is delayed by more than 12 hours as a result of you being admitted to a recognised hospital abroad as an in-patient.",
    name: "Pet Fees",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(
      Number(25),
      "US",
      "GBP",
      0,
      0
    )} per day, maximum ${formatAmount(Number(150), "US", "GBP", 0, 0)})`,
  },
  trip_cancellation: {
    alias: "trip_cancellation",
    description:
      "This benefit covers financial losses you suffer, such as non-refundable deposits and amounts you have paid for unused travel and accommodation, due to a trip that is cancelled or cut short.This includes cover as a result of you being diagnosed with COVID-19 within 14 days of departure and being unable to travel (for full benefits, exclusions and cover please refer to the Policy Document) ",

    name: "Cancellation, Curtailment and Missed Connection",
    validations: [{ product_package_validation_type: { name: "Is Required" } }],
    levels: [],
    benefit: `(${formatAmount(Number(5000), "US", "GBP", 0, 0)})`,
  },
};
